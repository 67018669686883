import { isOwnChannel } from '../system/channelManager'
import {
  each,
  filter,
  find,
  get,
  isEmpty,
  reduce,
  some,
  uniq,
  chain,
  uniqBy
} from 'lodash'
import { i18n } from '../vue-config/i18n'
import { CHANNEL_ID_GOOGLE_HOTEL_ADS } from '../../app/system/constants/channels'
import { TAX_SERVICE_COUNTRIES } from '../../app/constants'
import {
  roomPricePerNight,
  getLocalizedDate,
  sanitizeMarkup,
  convertPriceStringToFixedPrecision
} from '../system/helper'
import { DATE_TIME_FORMAT_DAY_MONTH_LONG } from '../constants'

export default {
  isPremium: (state) => state.subscription == 'premium',
  searchParams: ({ searchParams }) => searchParams,
  filters: (state, getters) => (filterName) => {
    let _filtersList = []
    switch (filterName) {
      case 'titles': {
        for (let item of state.rooms) {
          !_filtersList.includes(item.room.roomTypeName)
            ? _filtersList.push(item.room.roomTypeName)
            : ''
        }
        break
      }
      case 'amenities': {
        _filtersList = getters.allRoomsAmenities?.map(
          (amenity) => amenity?.codeDetail
        )
        break
      }
    }
    return _filtersList
  },
  filtersCode: (state, getters) => (filterName) => {
    switch (filterName) {
      case 'titles':
        const roomTypes = state.rooms?.map(
          (roomInfo) => roomInfo?.room?.roomTypeName
        )
        return uniq(roomTypes)
      case 'amenities':
        return getters.allRoomsAmenities?.map(
          (amenity) => amenity?.roomAmenityCode
        )
      default:
        return []
    }
  },
  // before price range filter
  roomsBeforeRender: (state) => state.roomsBeforeRender,
  // after price range filter but before checkbox filter
  rooms: (state) => state.rooms,
  productsAvailable: (state) => state.productsAvailable,
  roomFilters: (state) => state.roomFilters,
  allRoomsAmenities: (state) =>
    uniqBy(
      state.rooms.flatMap((roomInfo) => roomInfo.room?.amenities),
      'roomAmenityCode'
    ),
  // min and max price (for price range filter)
  defaultPriceRange: (state) => state.defaultPriceRange,
  numberOfActiveFilters: (state) =>
    filter(state.roomFilters, (f) => !isEmpty(f)).length,
  // fully filtered
  filteredRooms: (state) => state.filteredRooms,
  // lowest filtered room price
  filteredRoomMinPrice: (state) =>
    chain(state.productsAvailable)
      .map((room) => roomPricePerNight(room))
      .min()
      .value(),
  // total products count (for search result & pager)
  totalRooms: (state) => state.filteredRooms?.length || 0,

  availabilitiesLoaded: (state) => state.availabilitiesLoaded,
  productsLoaded: (state) => state.productsLoaded,
  roomById: (state) => (id) =>
    state.rooms.find(({ room }) => room?.id == id) ||
    state.rooms.find((room) => room.guestRoomId == id),
  isRoomOrProductAvailable: (state) => (id) => {
    if (state.rooms.some(({ guestRoomId }) => guestRoomId === id)) {
      return true
    }

    for (const room of state.rooms) {
      const products = room.products || []
      return products.some(({ productId }) => productId === id)
    }

    return false
  },
  productById: (state) => (id) =>
    state.productsAvailable.find((p) => p.productId == id) ||
    state.products.find((p) => p.id == id),
  productByRoomId: (state) => (id) =>
    state.filteredRooms.find(({ room }) => room?.id == id),
  productsByRoomTypeCode: (state) => (roomTypeCode) =>
    state.products.filter(
      ({ roomTypes }) => roomTypes[0]?.roomTypeCode === roomTypeCode
    ),
  productsByRatePlanCode: (state) => (ratePlanCode) =>
    state.products.filter(
      ({ ratePlans }) => ratePlans[0]?.ratePlanCode === ratePlanCode
    ),
  productMatchingRoomAndRateCode:
    (_, getters) => (roomTypeCode, ratePlanCode) => {
      return [
        ...getters.productsByRoomTypeCode(roomTypeCode),
        ...getters.productsByRatePlanCode(ratePlanCode)
      ].find(
        ({ roomTypes, ratePlans }) =>
          roomTypes[0]?.roomTypeCode === roomTypeCode &&
          ratePlans[0]?.ratePlanCode === ratePlanCode
      )
    },
  // Packages
  hotelPackagesLoaded: (state) => state.hotelPackagesLoaded,
  hotelPackages: ({ hotelPackages }) => hotelPackages,
  totalHotelPackages: (state) => state.hotelPackages.length,
  showHotelPackages: (_, { bookingRequest }) =>
    bookingRequest.roomStays.length === 1 &&
    bookingRequest.roomStays[0].order === null,
  hasHotelPublicPackages: (state) =>
    state.hotelPackages.some(({ publicPackage }) => publicPackage),
  hasHotelPackagesAmongResults: (_, { hotelPackages }) =>
    hotelPackages.some(({ showAmongResults }) => showAmongResults),
  hasHotelPackagesNotAmongResults: (state) =>
    state.hotelPackages.length &&
    state.hotelPackages.some(({ showAmongResults }) => !showAmongResults),
  hotelPackagesAmongResults: (_, { hotelPackages }) =>
    hotelPackages.filter(
      ({ showAmongResults, publicPackage }) => showAmongResults && publicPackage
    ),
  totalHotelPackagesAmongResults: (_, { hotelPackagesAmongResults }) =>
    hotelPackagesAmongResults.length,
  allHotelPackages: (state) => state.hotelPackages,
  getRoomsByHotelPackageId: (state) => (hotelPackageId) => {
    return state.hotelPackagesAvailable.filter((room) => {
      return room.packages.some(
        (hotelPackage) => hotelPackage.hotelPackageId === hotelPackageId
      )
    })
  },
  nbRoomsAndHotelPackages: (state) =>
    reduce(
      state.roomsBeforeRender,
      ({ nbRooms = 0, nbHotelPackages = 0 }, { hotelPackage }) => {
        return {
          nbRooms: !hotelPackage ? ++nbRooms : nbRooms,
          nbHotelPackages: hotelPackage ? ++nbHotelPackages : nbHotelPackages
        }
      },
      {}
    ),
  // Additional product featured
  // upgrade order
  upgrades: (state) => state.orderInfo.upgrades,
  // upsell products
  upsell: (state) => state.orderInfo.upsell,
  // list of available services
  services: (state) => state.services,
  // descriptive info for services
  servicesInfo: ({ servicesInfo }) =>
    chain(servicesInfo)
      .sortBy(['required', 'included'])
      .orderBy('featured', ['desc'])
      .value(),
  serviceInfoByInstanceId:
    ({ servicesInfo }) =>
    (id) => {
      return servicesInfo.filter((service) => service.instance === id)
    },
  // services data has been loaded
  servicesLoaded: (state) => state.servicesLoaded,

  language: (state) => state.language,
  currency: (state) => state.currency,
  hotelCurrency: (state) => state.hotelCurrency,
  isSameCurrency: (vm) => vm.currency === vm.hotelCurrency,
  // User
  // is user logged in
  logged: (state) => state.logged,
  // user info
  user: (state) => state.user,

  // Shopping CurrentRoomStay && Order info
  // currentRoomStay (roomStays)
  currentRoomStay: (state) => state.currentRoomStay,
  // localized date formater
  formatDate:
    (_, { language }) =>
    (date, options = DATE_TIME_FORMAT_DAY_MONTH_LONG) => {
      if (!date) {
        return
      }
      return getLocalizedDate(date, language, options)
    },
  // order info (used only for App needs)
  orderInfo: (state) => state.orderInfo,
  // cancelled order info (Trivago)
  orderCancelledInfo: (state) => state.orderCancelledInfo,
  // Credit card data is required when there's no rate plan in the session with "guaranteeType" === "None".
  isCreditCardRequired: ({ products, hotelPackages }, { roomStayOrders }) => {
    return some(
      roomStayOrders,
      ({ ratePlanId }) =>
        some(products, ({ ratePlans = [] }) =>
          some(
            ratePlans,
            ({ ratePlanCode, guaranteeType }) =>
              ratePlanCode === ratePlanId && guaranteeType !== 'None'
          )
        ) ||
        some(
          hotelPackages,
          ({ ratePlanId: id, guaranteeType }) =>
            id === ratePlanId && guaranteeType !== 'None'
        )
    )
  },
  // Check existence of a rate plan in the session with a given "type" (CC/DC/Voucher, None, PrePay).
  isGuaranteeType: (data, getters) => (type) =>
    some(getters.roomStayOrders, ({ ratePlanId }) =>
      some(data.products, ({ ratePlans }) =>
        some(
          ratePlans,
          ({ ratePlanCode, guaranteeType }) =>
            ratePlanCode === ratePlanId && guaranteeType === type
        )
      )
    ),
  servicesTotal: (state) => {
    let _servicesTotal = { price: 0, discount: 0 }
    for (let item of state.currentRoomStay.services) {
      if (!item.included) {
        _servicesTotal.price += item.totalPrice
        _servicesTotal.discount += item.totalDiscount
        _servicesTotal.hotelTotalDiscount += item.hotelTotalDiscount
        _servicesTotal.hotelPrice += item.hotelTotalPrice
      }
    }
    return _servicesTotal
  },
  measureCode: (state) => state.descriptiveInfo.areaUnitOfMeasureCode,

  // App utils
  // https://tourisoft.atlassian.net/wiki/spaces/HOT/pages/4325557/Hotel+Amenity+Code+HAC
  roomFacilitiesCodes: (state) => state.roomFacilitiesCodes,
  // for populating calendar (prices and disabled days)
  calendarData: (state) => state.calendarData,
  descriptiveInfo: (state) => state.descriptiveInfo,
  descriptiveInfoLoaded: (state) => state.descriptiveInfoLoaded,
  // guests: children, infants
  minInfantAge: (state) =>
    state.descriptiveInfo && state.descriptiveInfo.minInfantAge,
  maxInfantAge: (state) =>
    state.descriptiveInfo && state.descriptiveInfo.maxInfantAge,
  minChildAge: (state) =>
    state.descriptiveInfo && state.descriptiveInfo.minChildAge,
  maxChildAge: (state) =>
    state.descriptiveInfo && state.descriptiveInfo.maxChildAge,
  childrenCaption(_, { minChildAge, maxChildAge, maxInfantAge }) {
    if (minChildAge) {
      return i18n.t('Search#Form#Field#GuestsAge {minAge}-{maxAge} years old', {
        minAge: minChildAge,
        maxAge: maxChildAge
      })
    } else if (maxInfantAge) {
      return i18n.t('Search#Form#Field#GuestsAge {minAge}-{maxAge} years old', {
        minAge: maxInfantAge,
        maxAge: maxChildAge
      })
    } else {
      return i18n.t('Search#Form#Field#GuestsAge Under {maxAge} years old', {
        maxAge: maxChildAge
      })
    }
  },
  infantsCaption(_, { minInfantAge, maxInfantAge }) {
    if (minInfantAge) {
      return i18n.t('Search#Form#Field#GuestsAge {minAge}-{maxAge} years old', {
        minAge: minInfantAge,
        maxAge: maxInfantAge
      })
    } else {
      return i18n.t('Search#Form#Field#GuestsAge Under {maxAge} years old', {
        maxAge: maxInfantAge
      })
    }
  },

  // hotel time
  fromCheckInTime: (state) =>
    state.descriptiveInfo && state.descriptiveInfo.fromCheckInTime,
  toCheckInTime: (state) =>
    state.descriptiveInfo && state.descriptiveInfo.toCheckInTime,
  fromCheckOutTime: (state) =>
    state.descriptiveInfo && state.descriptiveInfo.fromCheckOutTime,
  toCheckOutTime: (state) =>
    state.descriptiveInfo && state.descriptiveInfo.toCheckOutTime,
  hotelTimezone: (state) => state.descriptiveInfo.timeZone,
  // hotel descriptive texts
  hotelDescriptiveTexts: (state) =>
    get(
      state,
      'descriptiveInfo.hotelInfo.descriptions.multimediaDescriptions.texts',
      []
    ),
  // rooms description
  roomsInfo: (state) =>
    get(state, 'descriptiveInfo.facilityInfo.guestRooms', []),
  roomInfoById: (_, getters) => (id) =>
    getters.roomsInfo &&
    find(getters.roomsInfo, (guestRoom) => guestRoom.id === id),
  // contact info
  contactInfo: (state) => get(state, 'descriptiveInfo.contactInfos[0]', {}),
  // allow to book additional room
  allowPayment: (_, getters) =>
    getters.isAllRoomStaysBooked && !isEmpty(getters.bookingRequest.guestInfo),
  paymentType: (state) => state.paymentType,
  isCCAvenuePayment: (_state, { isGuaranteeType, tenantId }) =>
    tenantId === 3 && isGuaranteeType('PrePay'),
  isDatatransPayment: (
    _state,
    { isCreditCardRequired, isGuaranteeType, tenantId }
  ) =>
    (isCreditCardRequired && tenantId !== 3) ||
    (tenantId === 3 && isCreditCardRequired && !isGuaranteeType('PrePay')),
  // guest info address required
  addressRequired: (state) => state.addressRequired || state.tenantId === 3,
  // allow Payment page
  hotelHomepage: (state) => state.hotelHomepage,
  //for home icon in mobile view
  // allow Thank You page
  allowThanks: (state) => state.allowThanks,
  reservationLoading: (state) => state.reservationLoading,
  // is app waiting for a cancellation
  cancellationLoading: (state) => state.cancellationLoading,
  // allow Cancellation confirmation page
  allowCancellationConfirm: (state) => state.allowCancellationConfirm,
  // is app load something
  loading: (state) => state.loading,
  // privacy policy
  defaultPrivacyPolicy: (_, { hotelDescriptiveTexts }) => {
    const privacyPolicy = hotelDescriptiveTexts?.find(
      (i) => i.detailsCode === 900
    )
    if (privacyPolicy) {
      return sanitizeMarkup(privacyPolicy.text, privacyPolicy.textformat)
    }
    return ''
  },
  privacyPolicy: ({ privacyPolicy }) => {
    if (privacyPolicy) {
      return sanitizeMarkup(privacyPolicy, 'Markdown')
    }
    return ''
  },
  // hotel Id
  hotelId: (state) => state.route.params.hotelId,
  // tenant Id
  tenantId: (state) => state.tenantId,
  // Google Analytics
  ga4ProductItems: ({ currency }, { roomStayOrders }) =>
    roomStayOrders.map(({ productId, room, totalPrice, discount }) => {
      return {
        currency,
        item_id: productId,
        item_category: 'room',
        item_name: room?.roomTypeName,
        price: convertPriceStringToFixedPrecision(totalPrice - discount),
        discount: convertPriceStringToFixedPrecision(discount) || 0
      }
    }),
  ga4ServiceItems: ({ currency }, { roomStayOrders, servicesInfo }) => {
    const serviceAttributes = roomStayOrders.flatMap((roomStay) =>
      roomStay.services.map(
        ({ serviceId, quantity, totalPrice = 0, discount }) => ({
          item_id: serviceId,
          item_name: servicesInfo.find(({ serviceId: id }) => id === serviceId)
            ?.name,
          item_category: 'service',
          price: convertPriceStringToFixedPrecision(totalPrice - discount),
          currency: currency,
          quantity: quantity || 1,
          discount: convertPriceStringToFixedPrecision(discount) || 0
        })
      )
    )
    return serviceAttributes
  },
  gaReservation: (
    { orderInfo, hotelCurrency },
    { reservationTotalPrice, bookingRequest, hotelId }
  ) => ({
    amount: convertPriceStringToFixedPrecision(reservationTotalPrice),
    currency: hotelCurrency,
    arrival: bookingRequest.checkIn,
    departure: bookingRequest.checkOut,
    reservationId: orderInfo?.reservationId,
    hotelId
  }),
  ga4Purchase: ({ orderInfo }, { ga4Cart }) => ({
    transaction_id: orderInfo?.reservationId,
    ...ga4Cart
  }),
  ga4Cart: (
    { searchParams, currency },
    { ga4ProductItems, ga4ServiceItems, reservationTotalPrice }
  ) => ({
    value: convertPriceStringToFixedPrecision(reservationTotalPrice),
    currency,
    coupon: searchParams.promoCode,
    items: [...ga4ProductItems, ...ga4ServiceItems]
  }),
  // GA4 'view_item' param
  ga4ViemItem: ({ orderInfo: { product = {} }, currency }) => {
    const value = convertPriceStringToFixedPrecision(
      product.totalPrice + product.totalLocalTax - product.discount
    )
    return {
      currency,
      value,
      items: [
        {
          item_id: product.productId,
          item_name: product.room?.roomTypeName
        }
      ]
    }
  },
  ga4ViewPackageItems: ({ currency, filteredRooms, searchParams }) => [],
  ga4ViewRoomItems: ({
    currency,
    filteredRooms,
    searchParams: { promoCode = '' }
  }) =>
    filteredRooms.map(({ id, roomTypeName, products = [] }, index) => {
      const { minPrice, totalDiscount } = products.reduce(
        (acc, product) => ({
          minPrice: Math.min(
            acc.minPrice,
            product.hotelTotalPrice - product.discount
          ),
          totalDiscount: Math.max(acc.totalDiscount, product.discount)
        }),
        { minPrice: Infinity, totalDiscount: 0 }
      )

      return {
        item_id: id,
        item_name: roomTypeName,
        item_category: 'room',
        ...(promoCode && { coupon: promoCode }),
        price: convertPriceStringToFixedPrecision(minPrice),
        discount: convertPriceStringToFixedPrecision(totalDiscount),
        currency,
        index
      }
    }),
  ga4ViewItemList: (_, { ga4ViewPackageItems, ga4ViewRoomItems }) => ({
    item_list_id: 'result_list',
    item_list_name: 'Search results',
    items: [...ga4ViewRoomItems, ...ga4ViewPackageItems]
  }),

  // GA4 'search' param
  ga4Search: (state) => {
    const {
      promoCode,
      sessionId,
      currencyCode,
      start,
      end,
      nbAdults,
      nbChildren,
      nbInfants
    } = state.searchParams
    return `Availability search: ${start} - ${end} for ${nbAdults} adults, ${nbChildren} children, and ${nbInfants} infants.${
      promoCode ? ` Promo code ${promoCode} is applicable.` : ''
    } Session ID: ${sessionId}.`
  },

  ga4AddToCart: (
    { currency },
    { ga4ProductItems, ga4ServiceItems, reservationTotalPrice }
  ) => ({
    value: convertPriceStringToFixedPrecision(reservationTotalPrice),
    currency,
    items: [...ga4ProductItems, ...ga4ServiceItems]
  }),
  // hotel name
  hotelName: (state) => state.descriptiveInfo.hotelName,
  // hotel phone number
  // sort default
  defaultSorting: (state) => state.defaultSorting,
  sorterName: (state) => state.sorterName,
  hotelPhoneNumber: (state) => {
    try {
      let phone = state.descriptiveInfo.contactInfos[0].phones.filter(
        (el) => el.phoneTechType == 1
      )[0]
      return `+${phone.countryAccessCode}${phone.phoneNumber}`
    } catch (error) {
      return 'N/A'
    }
  }, // hotel email address
  hotelEmailAddress: (state) => {
    return state.descriptiveInfo?.contactInfos?.[0]?.emails?.length > 0
      ? state.descriptiveInfo.contactInfos[0].emails[0].email
      : 'N/A'
  },
  hotelContacts: (_state, getters) => ({
    phoneNumber: getters.hotelPhoneNumber,
    emailAddress: getters.hotelEmailAddress
  }),
  // hotel promotions
  hasPromotions: (state) => state.hasPromotions && state.useOffers,
  // guests min/max
  guestsBounds: (state) => state.guestsBounds,
  isAdultsOnlyHotel: ({ guestsBounds }) => {
    const { children, infants } = guestsBounds

    return children.max === 0 && infants.max === 0
  },
  // configLoaded
  configLoaded: (state) => state.configLoaded,
  // general terms
  termstext: (state) => {
    let terms =
      state.descriptiveInfo &&
      state.descriptiveInfo.hotelInfo &&
      state.descriptiveInfo.hotelInfo.descriptions.multimediaDescriptions.texts.find(
        (t) => t.detailsCode == 901
      )
    return terms ? terms.text : null
  },
  // acceptedPaymentCards
  acceptedPaymentCards: (state) =>
    state.descriptiveInfo.acceptedPaymentCards ?? [],
  // return device that used to open app (touch or not-touch)
  device: () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
      ? 'mobile'
      : 'desktop',
  trackers: (state) => state.trackers,
  channelId: (state) => state.channelId,
  isGoogleAdsChannel: (state) =>
    state.channelId === CHANNEL_ID_GOOGLE_HOTEL_ADS,
  isOwnChannel: (state) => isOwnChannel(state.channelId),
  channelParametersByChannel: (state) =>
    state.channelParameters[state.channelId],
  reservationTotalPrice: (_, { bookingRequest }) => bookingRequest.totalPrice,
  reservationTotalLocalTax: (_, { roomStayOrders }) =>
    reduce(
      roomStayOrders,
      (grandTotalTax, product) => grandTotalTax + product.totalLocalTax,
      0
    ),
  reservationHotelTotalLocalTax: (_, { roomStayOrders }) =>
    reduce(
      roomStayOrders,
      (hotelGrandTotalTax, product) =>
        hotelGrandTotalTax + product.hotelTotalLocalTax,
      0
    ),
  customization: (state) => state.customization,
  allowPreferredColorScheme: (state) => state.allowPreferredColorScheme,
  theme: ({ customization }) =>
    customization.colorScheme || customization.isDarkMode ? 'dark' : 'light',
  customizationColorFields: ({ customization }) =>
    Object.keys(customization).reduce((next, key) => {
      if (key.match(/^color.*$/)) {
        return { ...next, [key]: customization[key] }
      } else {
        return next
      }
    }, {}),
  customizationFormSending: (state) => state.customizationFormSending,
  roomAvailabilities: ({ serverRoomAvailabilities }, { roomStayOrders }) => {
    const roomAvailabilities = { ...serverRoomAvailabilities }

    each(roomStayOrders, ({ guestRoomId }) => {
      const roomAvailability = roomAvailabilities[guestRoomId]
      if (roomAvailability) {
        roomAvailabilities[guestRoomId]--
      }
    })

    return roomAvailabilities
  },
  inlineBookingError: (state) => state.inlineBookRequestError,
  roomProducts(state, getters) {
    return reduce(
      getters.descriptiveInfo?.facilityInfo?.guestRooms,
      (accumulator, guestRoom) => ({
        ...accumulator,
        [guestRoom.id]:
          find(state.rooms, (room) => room.guestRoomId === guestRoom.id)
            ?.products ?? []
      }),
      {}
    )
  },
  isTaxServiceEnabled: (_, { descriptiveInfo }) => {
    return TAX_SERVICE_COUNTRIES.includes(
      descriptiveInfo.contactInfos[0]?.addresses[0].countryCode
    )
  }
}
